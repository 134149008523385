import { Grid, Image, Stack, Title } from "@mantine/core";
import { EnquiryForm } from "../../components/EnquiryForm";
import WorldMap from "../../images/world-map.png";

export function ContactUs(): JSX.Element {
  return (
    <Stack>
      <Title ta="center">We are available worldwide</Title>
      <Grid>
        <Grid.Col span={{ base: 12, xs: 12, sm: 7 }}>
          <Image component="img" src={WorldMap.src} srcSet={WorldMap.srcSet} width="100%" />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 12, sm: 5 }}>
          <EnquiryForm
            {...{
              email: "",
              message: "",
              name: "",
              phone: "",
            }}
          />
        </Grid.Col>
      </Grid>
    </Stack>
  );
}
