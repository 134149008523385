import ComputerIcon from "../../images/computer.png";

export interface CoursesDatum {
  image: ResponsiveImageOutput;
  name: string;
  description: string;
}

export const CoursesData: CoursesDatum[] = [
  {
    image: ComputerIcon,
    name: "Maths",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "Physics",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "Chemistry",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "Biology",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "Commerce",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "Economics",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "Accountancy",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "English",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "Sociology",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "Geography",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "Computer Science",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    image: ComputerIcon,
    name: "Political Science",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
];
