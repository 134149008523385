import Boy from "../../images/avatars/boy.png";
import Dad from "../../images/avatars/dad.png";
import Girl from "../../images/avatars/girl.png";
import Mom from "../../images/avatars/mom.png";

export interface TestimonialDatum {
  id: string;
  image: ResponsiveImageOutput;
  name: string;
  title: string;
  text: string;
  date: string;
}

export const TestimonialData: TestimonialDatum[] = [
  {
    id: "1",
    image: Mom,
    name: "Jurgeni",
    title: "Parent",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta asperiores ab",
    date: "23rd April 2022",
  },
  {
    id: "2",
    image: Dad,
    name: "Jurgeni",
    title: "Parent",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta asperiores ab",
    date: "23rd April 2022",
  },
  {
    id: "3",
    image: Girl,
    name: "Jurgeni",
    title: "University Student",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta asperiores ab",
    date: "23rd April 2022",
  },
  {
    id: "4",
    image: Boy,
    name: "Jurgeni",
    title: "University Student",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta asperiores ab",
    date: "23rd April 2022",
  },
  {
    id: "5",
    image: Mom,
    name: "Jurgeni",
    title: "Parent",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta asperiores ab",
    date: "23rd April 2022",
  },
  {
    id: "6",
    image: Dad,
    name: "Jurgeni",
    title: "Parent",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta asperiores ab",
    date: "23rd April 2022",
  },
  {
    id: "7",
    image: Girl,
    name: "Jurgeni",
    title: "University Student",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta asperiores ab",
    date: "23rd April 2022",
  },
  {
    id: "8",
    image: Boy,
    name: "Jurgeni",
    title: "University Student",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta asperiores ab",
    date: "23rd April 2022",
  },
  {
    id: "9",
    image: Mom,
    name: "Jurgeni",
    title: "Parent",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta asperiores ab",
    date: "23rd April 2022",
  },
];
