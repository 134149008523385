import { Button, Grid, Image, Text, Title } from "@mantine/core";
import { NavLink } from "react-router-dom";
import ClassRoom from "../../images/classroom.jpg";

export function Home() {
  return (
    <Grid>
      <Grid.Col span={{ base: 12, xs: 12, sm: 6 }}>
        <Title>Building Better</Title>
        <Title>Assignments</Title>
        <Text>
          Implementing best pedagogical practices can be nearly impossible. We make it easy.
        </Text>
        <Button component={NavLink} to="#" variant="contained" radius="md">
          Getting Started
        </Button>
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 12, sm: 6 }}>
        <Image
          src={ClassRoom.src}
          srcSet={ClassRoom.srcSet}
          alt="Tutor"
          loading="lazy"
          width="100%"
          height="100%"
        />
      </Grid.Col>
    </Grid>
  );
}
