import { z as zod } from "zod";

// Adopted from App repo. Ref: https://github.com/manufac-analytics/app/blob/9c8c76f79376391ff9ccb924a8d684bf1df91ba3/packages/schemas/src/identity/user.ts#L6
const PhoneNumberRegex = /^$|^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const EnquiryData = zod.object({
  name: zod.string().min(1), // 'zod.nonempty()' has been deprecated
  email: zod
    .string()
    .email()
    .transform((val) => val.toLowerCase()),
  phone: zod
    .string()
    .regex(PhoneNumberRegex, "Should be a valid contact number like 123 456 7890, +91 (123) 456-7890, etc.")
    .transform((val) => (val === "" ? undefined : val)),
  message: zod.string().min(1).max(500), // 'zod.nonempty()' has been deprecated
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EnquiryData = zod.infer<typeof EnquiryData>;

export interface EnquiryFormProps extends EnquiryData {
  onSubmitHook?: () => void;
}

export const EnquiryFormNames: Record<keyof EnquiryFormProps, keyof EnquiryFormProps> = {
  name: "name",
  message: "message",
  email: "email",
  phone: "phone",
  onSubmitHook: "onSubmitHook",
};
