import { Stack, Title, Grid, Card, Image, Text, Button } from "@mantine/core";
import { useState } from "react";
import type { CoursesDatum } from "./utils";

interface CoursesProps {
  limit: number;
  data: CoursesDatum[];
}

export function Courses({ limit, data }: CoursesProps) {
  const [showMoreCourses, setShowMoreCourses] = useState(false);

  const handleShowMoreCoursesClick = () => {
    setShowMoreCourses((prev) => !prev);
  };

  return (
    <Stack>
      <Title ta="center">
        Find <strong>Online Tutor</strong> in any <strong>Subject</strong>
      </Title>
      <Grid>
        {data
          .slice(0, showMoreCourses === true ? undefined : limit)
          .map(({ image: { src, srcSet }, name, description }) => (
            <Grid.Col span={{ base: 12, xs: 6, sm: 3 }} key={name}>
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src={src}
                    srcSet={srcSet}
                    style={{ height: "80px", width: "80px", margin: "auto" }}
                    alt="Course icon"
                  />
                </Card.Section>
                <Text fw="bold" ta="center" p={15}>
                  {name}
                </Text>
                <Text ta="center">{description}</Text>
              </Card>
            </Grid.Col>
          ))}
      </Grid>
      <Button onClick={handleShowMoreCoursesClick}>
        {showMoreCourses === true ? "Show Less" : "Show More"}
      </Button>
    </Stack>
  );
}
