import {
  Anchor,
  AppShell,
  Group,
  Image,
  NavLink as MantineNavLink,
  Button,
  ActionIcon,
} from "@mantine/core";
import { IconMoonStars, IconSun } from "@tabler/icons-react";
import { NavLink, useLocation } from "react-router-dom";
import DarkLogo from "../../images/logo-dark.png";
import LightLogo from "../../images/logo-light.png";
import { SectionIDs, NavLinks } from "../../utils";
import type { CustomAppBarProps } from "../../utils";

export function CustomAppBar({ colorScheme, onToggleColorScheme }: CustomAppBarProps): JSX.Element {
  const location = useLocation();

  return (
    <AppShell.Header p="xs" visibleFrom="lg">
      <Group justify="space-between">
        <Anchor title="Home" href="/">
          <Image
            height="40px"
            width="157px"
            fit="contain"
            alt="Company logo"
            src={colorScheme === "dark" ? DarkLogo : LightLogo}
          />
        </Anchor>
        <Group wrap="nowrap">
          {SectionIDs.map((name) => (
            <MantineNavLink
              key={name}
              active={location.pathname === `/#${NavLinks[name]}`}
              href={`/#${NavLinks[name]}`}
              label={name}
              w="auto"
            />
          ))}
        </Group>
        <Group>
          <Button component={NavLink} to="#">
            Sign In
          </Button>
          <Button component={NavLink} to="#">
            Sign Up
          </Button>
          <ActionIcon
            variant="default"
            title={colorScheme === "dark" ? "Change To Light Mode" : "Change To Dark Mode"}
            onClick={onToggleColorScheme}
          >
            {colorScheme === "dark" ? <IconSun /> : <IconMoonStars />}
          </ActionIcon>
        </Group>
      </Group>
    </AppShell.Header>
  );
}
