import {
  ActionIcon,
  Anchor,
  AppShell,
  Group,
  Image,
  Drawer,
  Stack,
  NavLink as MantneNavLink,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconLogin, IconMenu2, IconMoonStars, IconSun, IconUserPlus } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import DarkLogo from "../../images/logo-dark.png";
import LightLogo from "../../images/logo-light.png";
import { SectionIDs, NavLinks } from "../../utils";
import type { CustomAppBarProps } from "../../utils";

export function CustomMobileAppBar({
  colorScheme,
  onToggleColorScheme,
}: CustomAppBarProps): JSX.Element {
  const [opened, { open, close }] = useDisclosure(false);
  const location = useLocation();

  return (
    <>
      <AppShell.Header p="md" hiddenFrom="lg">
        <Group justify="space-between">
          <Anchor title="Home" href="/">
            <Image
              height="40px"
              width="157px"
              fit="contain"
              alt="Company logo"
              src={colorScheme === "dark" ? DarkLogo : LightLogo}
            />
          </Anchor>
          <Group>
            <ActionIcon variant="subtle" title="Login">
              <IconLogin />
            </ActionIcon>
            <ActionIcon variant="subtle" title="Register">
              <IconUserPlus />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              title={colorScheme === "dark" ? `Change To Light Mode` : `Change To Dark Mode`}
              onClick={onToggleColorScheme}
            >
              {colorScheme === "dark" ? <IconSun /> : <IconMoonStars />}
            </ActionIcon>
            <ActionIcon variant="subtle" title="Menu" onClick={open}>
              <IconMenu2 />
            </ActionIcon>
          </Group>
        </Group>
      </AppShell.Header>
      <Drawer opened={opened} onClose={close} title="Tutor" size="xs" position="left">
        <Stack gap="xs">
          {SectionIDs.map((name) => (
            <MantneNavLink
              key={name}
              active={location.pathname === `/#${NavLinks[name]}`}
              href={`/#${NavLinks[name]}`}
              label={name}
              onClick={close}
            />
          ))}
        </Stack>
      </Drawer>
    </>
  );
}
