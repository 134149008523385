import TeacherFemale from "../../images/teacher-female.png";
import TeacherMale from "../../images/teacher-male.png";

interface TeachersDatum {
  id: string;
  image: ResponsiveImageOutput;
  name: string;
  title: string;
  description: string;
}

export const TeachersData: TeachersDatum[] = [
  {
    id: "1",
    image: TeacherFemale,
    name: "Name",
    title: "Title",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est recusandae doloremque ipsa perferendis.",
  },
  {
    id: "2",
    image: TeacherMale,
    name: "Name",
    title: "Title",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est recusandae doloremque ipsa perferendis.",
  },
  {
    id: "3",
    image: TeacherFemale,
    name: "Name",
    title: "Title",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est recusandae doloremque ipsa perferendis.",
  },
  {
    id: "4",
    image: TeacherMale,
    name: "Name",
    title: "Title",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est recusandae doloremque ipsa perferendis.",
  },
  {
    id: "5",
    image: TeacherFemale,
    name: "Name",
    title: "Title",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est recusandae doloremque ipsa perferendis.",
  },
  {
    id: "6",
    image: TeacherMale,
    name: "Name",
    title: "Title",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est recusandae doloremque ipsa perferendis.",
  },
];
