import { kebabCase } from "lodash-es";
import type { MantineColorScheme } from "@mantine/core";

export interface CustomAppBarProps {
  colorScheme: MantineColorScheme;
  onToggleColorScheme: () => void;
}

export const NavLinks = {
  "Why Us": kebabCase("Why Us"),
  Courses: kebabCase("Courses"),
  Testimonials: kebabCase("Testimonials"),
  "Our Team": kebabCase("Our Team"),
  "Contact Us": kebabCase("Contact Us"),
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type NavLinks = keyof typeof NavLinks;

export const SectionIDs: NavLinks[] = ["Why Us", "Courses", "Testimonials", "Our Team", "Contact Us"];
