import ComputerIcon from "../../images/computer.png";

export interface AboutUsDatum {
  image: ResponsiveImageOutput;
  title: string;
  description: string;
}

export const AboutUsData: AboutUsDatum[] = [
  {
    image: ComputerIcon,
    title: "One-On-One Teaching",
    description: "All of Our Special Education Experts Have A Degree In Special Education.",
  },
  {
    image: ComputerIcon,
    title: "24/7 Tutor Availability",
    description: "All of Our Special Education Experts Have A Degree In Special Education.",
  },
  {
    image: ComputerIcon,
    title: "Learn anytime, anywhere",
    description: "24/7 tutor availability assured at MyTutor matched to your learning!",
  },
  {
    image: ComputerIcon,
    title: "Interactive Whiteboard",
    description: "Our digital whiteboard equipped with audio-video-chat features.",
  },
];
