import { ActionIcon, Anchor, AppShell, Group, Image, Stack, Text } from "@mantine/core";
import {
  IconBrandFacebookFilled,
  IconBrandGoogleFilled,
  IconBrandInstagram,
  IconBrandLinkedin,
} from "@tabler/icons-react";
import DarkLogo from "../../images/logo-dark.png";
import LightLogo from "../../images/logo-light.png";
import type { MantineColorScheme } from "@mantine/core";

export interface FooterProps {
  colorScheme?: MantineColorScheme;
}

export function Footer({ colorScheme }: FooterProps): JSX.Element {
  return (
    <AppShell.Footer pos="relative">
      <Stack align="center">
        <Anchor title="Home" href="/">
          <Image
            src={colorScheme === "dark" ? DarkLogo : LightLogo}
            alt="Company logo"
            h={50}
            w={200}
          />
        </Anchor>
        <Text ta="center">
          MyTutor is the best online tutoring platform in the world. Lorem ipsum dolor sit amet
          consectetur adipisicing elit.
        </Text>
        <Group justify="center">
          <ActionIcon variant="default">
            <IconBrandGoogleFilled />
          </ActionIcon>
          <ActionIcon variant="default">
            <IconBrandInstagram />
          </ActionIcon>
          <ActionIcon variant="default">
            <IconBrandFacebookFilled />
          </ActionIcon>
          <ActionIcon variant="default">
            <IconBrandLinkedin />
          </ActionIcon>
        </Group>
        <Text ta="center">
          © 2020-{new Date().getFullYear()} | Manufac Analytics Private Limited | 🇮🇳 DPIIT
          Recognized 🎖️
        </Text>
      </Stack>
    </AppShell.Footer>
  );
}
