import { Stack, Title, Grid, Card, Image, Text } from "@mantine/core";
import { TeachersData } from "./utils";

export function OurTeam(): JSX.Element {
  return (
    <Stack>
      <Title ta="center">Our Team</Title>
      <Grid>
        {TeachersData.map(({ id, name, title, image: { src, srcSet }, description }) => (
          <Grid.Col span={{ base: 12, sm: 6, md: 4 }} key={id}>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Card.Section p={15}>
                <Image src={src} srcSet={srcSet} w="150px" h="150px" m="auto" />
              </Card.Section>
              <Text size="lg" fw="bold" ta="center">
                {name}
              </Text>
              <Text ta="center">{title}</Text>
              <Text ta="center" pt={15}>
                {description}
              </Text>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </Stack>
  );
}
