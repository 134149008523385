import { send } from "@emailjs/browser";
import { Stack, Title, Text, TextInput, Textarea, Button } from "@mantine/core";
import { IconSend } from "@tabler/icons-react";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import { EnquiryFormNames, EnquiryData } from "./utils";
import type { EnquiryFormProps } from "./utils";
import type { FormikConfig, FormikErrors } from "formik";

// TODO: update this keys
const EmailJSKeys = {
  serviceID: "service_u52pzd8",
  templateID: "template_3uy65wi",
  publicID: "JGMR1-eH-smwxh7rB",
};

// Adopted from App repo. Ref: https://github.com/manufac-analytics/app/blob/9c8c76f79376391ff9ccb924a8d684bf1df91ba3/packages/forms/src/components/Contact-Form/index.tsx#L36
const validateHandler: FormikConfig<EnquiryFormProps>["validate"] = (values) => {
  let formikErrors: FormikErrors<EnquiryFormProps> = {};
  const parsedData = EnquiryData.safeParse(values);
  if (parsedData.success === false) {
    formikErrors = parsedData.error.errors.reduce<FormikErrors<EnquiryFormProps>>(
      (curr, error) => ({
        ...curr,
        [error.path.toString()]: error.message,
      }),
      {},
    );
  }

  return formikErrors;
};

export function EnquiryForm(props: EnquiryFormProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { serviceID, publicID, templateID } = EmailJSKeys;

  const onSubmitHandler: FormikConfig<EnquiryFormProps>["onSubmit"] = async (
    values,
    { resetForm },
  ) => {
    try {
      const data = EnquiryData.safeParse(values);
      if (data.success === true) {
        await send(serviceID, templateID, data.data, publicID);
        enqueueSnackbar("Enquiry sent successfully.");
        resetForm();
      }
    } catch (err) {
      if (err instanceof Error) {
        enqueueSnackbar(`Error on sending enquiry: ${err.message}`);
      }
    } finally {
      const { onSubmitHook } = props;
      onSubmitHook?.();
    }
  };

  return (
    <Formik initialValues={props} onSubmit={onSubmitHandler} validate={validateHandler}>
      {({ values, handleChange, handleBlur, isSubmitting, errors, touched }) => (
        <Form style={{ width: "100%" }}>
          <Stack>
            <Title order={3}>Contact Us</Title>
            <Text>Send us a message. We will get back to you as soon as possible.</Text>
            <TextInput
              name={EnquiryFormNames.name}
              label="Full Name"
              placeholder="Enter full name"
              required
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name !== undefined && touched.name === true ? errors.name : ""}
            />
            <TextInput
              name={EnquiryFormNames.email}
              label="Email"
              placeholder="Enter email"
              required
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email !== undefined && touched.email === true ? errors.email : ""}
            />

            <TextInput
              name={EnquiryFormNames.phone}
              label="Phone"
              placeholder="Enter phone number"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.phone !== undefined && touched.phone === true ? errors.phone : ""}
            />
            <Textarea
              name={EnquiryFormNames.message}
              label="Message"
              autosize
              minRows={4}
              placeholder="Enter you message here"
              required
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.message !== undefined && touched.message === true ? errors.message : ""}
            />
            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={isSubmitting}
              leftSection={<IconSend />}
            >
              Send
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
