import { Card, Stack, Title, Image, Text, Grid } from "@mantine/core";
import type { AboutUsDatum } from "./utils";

interface AboutUsProps {
  data: AboutUsDatum[];
}

export function AboutUs({ data }: AboutUsProps) {
  return (
    <Stack>
      <Title ta="center" fw="bold">
        Why Us?
      </Title>
      <Grid>
        {data.map(({ image: { src, srcSet }, title, description }) => (
          <Grid.Col span={{ base: 12, xs: 6, sm: 3 }} key={title}>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Card.Section>
                <Image
                  src={src}
                  srcSet={srcSet}
                  style={{ height: "80px", width: "80px", margin: "auto" }}
                  alt="Course icon"
                />
              </Card.Section>
              <Text fw="bold" ta="center" p={15}>
                {title}
              </Text>
              <Text ta="center">{description}</Text>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </Stack>
  );
}
