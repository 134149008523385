import { Stack, Title, Grid, Card, Text, Image, Button } from "@mantine/core";
import { useState } from "react";
import type { TestimonialDatum } from "./utils";

interface TestimonialProps {
  limit: number;
  data: TestimonialDatum[];
}

export function Testimonials({ limit, data }: TestimonialProps) {
  const [isExpanded, setExpanded] = useState(false);

  const showMoreTestimonials = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Stack>
      <Title ta="center">Don&#39;t take our word for it.</Title>
      <Title
        variant="h4"
        ta="center"
        style={{
          background: "linear-gradient(62deg, rgba(2, 104, 125, 1) 32%, rgba(0, 255, 226, 1) 100%)",
          WebkitTextFillColor: "transparent",
          WebkitBackgroundClip: "text",
        }}
      >
        Trust our customers
      </Title>
      <Grid w="80%" m="auto">
        {data
          .slice(0, isExpanded === true ? undefined : limit)
          .map(({ id, image: { src, srcSet }, name, title, text, date }) => (
            <Grid.Col span={{ base: 12, xs: 12, sm: 4 }} key={id}>
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section p={15}>
                  <Image w={60} h={60} radius={5} m="auto" src={src} srcSet={srcSet} />
                </Card.Section>
                <Text size="lg" fw="bold" ta="center">
                  {name}
                </Text>
                <Text ta="center">{title}</Text>
                <Text pt={15}>{text}</Text>
                <Text ta="end">{date}</Text>
              </Card>
            </Grid.Col>
          ))}
      </Grid>

      <Button onClick={showMoreTestimonials}>
        {isExpanded === true ? "Show Less" : "Show More"}
      </Button>
    </Stack>
  );
}
