import { AppShell, Grid, useMantineColorScheme } from "@mantine/core";
import { SnackbarProvider } from "notistack";
import { useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CustomAppBar } from "./components/CustomAppBar";
import { CustomMobileAppBar } from "./components/CustomMobileAppBar";
import { Footer } from "./components/Footer";
import { AboutUs } from "./sections/AboutUs";
import { AboutUsData } from "./sections/AboutUs/utils";
import { ContactUs } from "./sections/ContactUs";
import { Courses } from "./sections/Courses";
import { CoursesData } from "./sections/Courses/utils";
import { Home } from "./sections/Home";
import { OurTeam } from "./sections/OurTeam";
import { Testimonials } from "./sections/Testimonials";
import { TestimonialData } from "./sections/Testimonials/utils";
import { NavLinks } from "./utils";
import type { RefCallback } from "react";

export function App() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const ref = useRef<Record<string, HTMLDivElement>>({});
  const refCallback: RefCallback<HTMLDivElement> = (element) => {
    if (element !== null) {
      const { id } = element;
      ref.current[id] = element;
    }
  };

  const navigate = useNavigate();
  const intersectionObserverCallback: IntersectionObserverCallback = useCallback(
    (entries) => {
      const intersectedEntry = entries.find((entry) => entry.isIntersecting === true);
      if (intersectedEntry !== undefined) {
        const { id } = intersectedEntry.target;
        const url = id === "/" ? id : `/#${id}`;
        navigate(url);
      }
    },
    [navigate],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionObserverCallback, {
      threshold: 0.5,
    });

    Object.values(ref.current).forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, [intersectionObserverCallback]);

  return (
    <SnackbarProvider>
      <AppShell padding="xl">
        <AppShell.Header>
          <CustomMobileAppBar colorScheme={colorScheme} onToggleColorScheme={toggleColorScheme} />
          <CustomAppBar colorScheme={colorScheme} onToggleColorScheme={toggleColorScheme} />
        </AppShell.Header>
        <AppShell.Main>
          <Grid justify="center">
            <Grid.Col id="/" ref={refCallback}>
              <Home />
            </Grid.Col>
            <Grid.Col id={NavLinks["Why Us"]} ref={refCallback}>
              <AboutUs data={AboutUsData} />
            </Grid.Col>
            <Grid.Col id={NavLinks.Courses} ref={refCallback}>
              <Courses limit={8} data={CoursesData} />
            </Grid.Col>
            <Grid.Col id={NavLinks.Testimonials} ref={refCallback}>
              <Testimonials limit={6} data={TestimonialData} />
            </Grid.Col>
            <Grid.Col id={NavLinks["Our Team"]} ref={refCallback}>
              <OurTeam />
            </Grid.Col>
            <Grid.Col id={NavLinks["Contact Us"]} ref={refCallback}>
              <ContactUs />
            </Grid.Col>
          </Grid>
        </AppShell.Main>
        <AppShell.Footer pos="static">
          <Footer colorScheme={colorScheme} />
        </AppShell.Footer>
      </AppShell>
    </SnackbarProvider>
  );
}
